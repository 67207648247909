import { Fragment, MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomButton from "components/CustomButton";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import EditTopic from "./EditTopic";

import "./EditAffiliation.scss";
import { IRextState } from "baseProvider/rext";
import { getAffiliationState, getArchetypeState, getPracticeAreaState, getPracticeTopicPermissionState, getUserAllAffiliationsState, getUserRoleState } from "store/selectors";
import { fetchPracticeTopicPermissionMapping, getAffiliationRequest, getArchetypeRequest, getPracticeAreaRequest, getUserRolesRequest } from "store/rext";
import { IOption } from "components/TreeDropdown";
import Loading from "components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import UsePeople from "HOC/UsePeople";
import { getUserDetail, getUserName } from "utils";
import ProfilePicture from "components/ProfilePicture";
import deepEqual from "deep-equal";
import { IListState } from "baseProvider/list";
import { requestUserAllAffiliationsList, updateUserAffiliations } from "store/list";
import usePrevious from "HOC/UsePrevious";
import { modalOperation } from "store/actions";
import { ModalsType } from "containers/ModalManager/ModalManager";
import { ITopic } from "interface";
import { setGlobalPageLoad } from "Analytics";
import CheckboxLabel from "components/CheckboxLabel";
import { useOktaAuth } from "@okta/okta-react";
import { checkIfPapAdminRolesExists, hasPACRoleOnly, hasPAPOnlyAccess } from "containers/SearchPaaf/utils";
import { MultiSelectDropdown } from "components/MultiSelectDropdown";

function EditAffiliations() {
  const { hrid } = useParams();
  const dispatch = useDispatch();
  const { user, fetching } = UsePeople(hrid!);
  const navigate = useNavigate();

  const { authState } = useOktaAuth();
  const { hrid: loggedInUserHrid } = getUserDetail(authState!);

  const practiceAreaState: IRextState = useSelector(getPracticeAreaState, deepEqual);
  const affiliationLevelState: IRextState = useSelector(getAffiliationState, deepEqual);
  const archetypeState: IRextState = useSelector(getArchetypeState, deepEqual);
  const userAffiliationsState: IListState = useSelector(getUserAllAffiliationsState, deepEqual);
  const [topicChanges, setTopicChanges] = useState<{ [key: string]: ITopic }>({});
  const [isPapRole, setIsPapRole] = useState<boolean>(false);
  const [isAdminPapRole, setIsAdminPapRole] = useState<boolean>(false);
  const [isPacRole, setIsPacRole] = useState<boolean>(false);
  const [mdpArcheType, setMdpArcheType] = useState<string>('');
  const previousUpdationStatus = usePrevious(userAffiliationsState.operationProgress);
  const practiceTopicPermissionState: IRextState = useSelector(getPracticeTopicPermissionState, deepEqual);
  const { data: userRoleState, fetching: userRoleFetching, error: userFetchingError } = useSelector(getUserRoleState);
  const [expanded, setExpanded] = useState<any>({
    id: '',
    expanded: false,
    practiceAreaTitle: '',
    isGlobalChecked: false
  });
  const [globalChecked, setGlobalChecked] = useState(false);

  const handleBackNavigation = useCallback(() => {
    navigate(-1);
  }, []);

  const handleChange = useCallback((id: string, topicState: ITopic) => {
    setTopicChanges((topics) => {
      if (topicState && topicState.comment?.length === 0 && topicState?.leadAllocation?.length === 0) {
        delete topics[id];
      } else {
        topics[id] = topicState;
      }
      return { ...topics }
    }
    );
  }, []);

  const handleDelete = useCallback((id: string) => {
    setTopicChanges((topics) => {
      delete topics[id];
      return { ...topics };
    }
    );
  }, []);

  const handleSubmitClick = () => {
    if (Object.keys(topicChanges).length && hrid) {
      dispatch(modalOperation.showModal(ModalsType.SubmitAffiliationModal, {
        topicChanges,
        onSave: (updatedTopics: any) => {
          if (Object.keys(updatedTopics).length && hrid) {
            dispatch(updateUserAffiliations(updatedTopics, hrid, mdpArcheType));
          }
          dispatch(modalOperation.hideModal());
        },
        onClose: () => {
          dispatch(modalOperation.hideModal());
        },
        title: "Confirmation",
      }));
    } else if (mdpArcheType !== '') {
      if (hrid) {
        dispatch(updateUserAffiliations([], hrid, mdpArcheType));
      }
    }
  }

  const handleExpand = useCallback((data: any): any => {
    setGlobalChecked(false);
    setExpanded(data);
  }, []);

  const handleExpandCollapse = ({ target: { checked } }: any) => {
    setGlobalChecked(checked);
    const data: any = {
      id: '',
      expanded: true,
      practiceAreaTitle: '',
      isGlobalChecked: checked
    }
    setExpanded(data)
  };


  const renderAllPracticeArea = (isParent: boolean, parentPracticeArea?: string, index?: number) => (practiceArea: IOption, childIndex: number) => {
    const { id, value, childrens } = practiceArea;
    return (
      <Fragment key={id}>
        {practiceTopicPermissionState.data[id] && <EditTopic deleteTopic={handleDelete} isParent={isParent}
          expand={handleExpand}
          isExpaned={expanded}
          parentPracticeArea={parentPracticeArea}
          isAdminPAP={isAdminPapRole}
          isPAPOnly={isPapRole}
          isPACOnly={isPacRole}
          previousUpdationStatus={!!previousUpdationStatus} currentUpdationStatus={!!userAffiliationsState.operationProgress}
          title={value} id={id} defaultTopic={userAffiliationsState?.raw[id]} handleChange={handleChange} />}
        {
          practiceArea.value === expanded.practiceAreaTitle && childrens?.map(renderAllPracticeArea(false, value, index))
        }
        {
          globalChecked && childrens?.map(renderAllPracticeArea(false, value, index))
        }
      </Fragment>
    )
  }

  const renderCategoryPracticeArea = (practiceAreaCategory: IOption, index: number) => {
    const { childrens } = practiceAreaCategory;
    return (
      <Fragment key={`category${index}`}>
        {/* <tr key={`category${index}`} className={childrens?.length ? "dark" : ""}>
          <td colSpan={5}>{value}</td>
        </tr> */}
        {childrens?.map(renderAllPracticeArea(true, '', index))}
      </Fragment>
    )
  }

  // To be removed
  const checkLeadAllocation = (allocationData: any) => {
    let total = 0;
    for (const item of allocationData) {
      total += parseInt(item.leadAllocation);
      if (total > 100) {
        return false;
      }
    }
    return total === 100;
  };

  useEffect(() => {
    dispatch(requestUserAllAffiliationsList(hrid!));
    if (!practiceAreaState.data.dropdown.length) {
      dispatch(getPracticeAreaRequest());
    }

    if (!affiliationLevelState.data.length) {
      dispatch(getAffiliationRequest());
    }

    if (!archetypeState.data.length) {
      dispatch(getArchetypeRequest());
    }

    dispatch(fetchPracticeTopicPermissionMapping());
    // dispatch(getUserRolesRequest(hrid || ''));
  }, [hrid])

  const handleArchetypeChanges = (data: any) => {
    setMdpArcheType(data);
  };

  useEffect(() => {
    if (loggedInUserHrid) {
      dispatch(getUserRolesRequest(loggedInUserHrid || ''));
    }
  }, [loggedInUserHrid])


  useEffect(() => {
    if (previousUpdationStatus && !userAffiliationsState.operationProgress) {
      setTopicChanges({});
    }
  }, [userAffiliationsState.operationProgress, previousUpdationStatus])

  useEffect(() => {
    setGlobalPageLoad("Edit Affiliations Page");
  }, [])

  useEffect(() => {
    if (userRoleState !== undefined && Object.keys(userRoleState).length) {
      //   const dummyRole = {
      //     roles: ['ADMIN', 'CDS', 'PAP', 'PAC']
      //     // roles: ['CDS']
      //     // roles: ['PAC']
      // }
      const papAccessOnly = hasPAPOnlyAccess(userRoleState);
      const papAdminAccess = checkIfPapAdminRolesExists(userRoleState);
      const pacAccess = hasPACRoleOnly(userRoleState);
      setIsPapRole(papAccessOnly);
      setIsAdminPapRole(papAdminAccess);
      setIsPacRole(pacAccess);
    }
  }, [userRoleState])

  useEffect(() => {
    setGlobalChecked(globalChecked);
  }, [globalChecked]);


  return (
    <Fragment>
      <div className='padding-t-5 padding-b-5 padding-l-10 padding-r-10'>
        <CustomButton
          buttonText="Back"
          primaryButton
          noOutline
          noPadding
          transparent
          iconPosition={ICON_POSITION.LEFT}
          iconProps={{
            name: CUSTOM_SVG_ICON.CaretLeft,
            svgType: SVGType.CUSTOM,
            size: "small"
          }}
          handleClick={handleBackNavigation}
        />
        <div className="text-uppercase padding-t-4 padding-b-4">Edit User</div>
        {user && <div className="background-grey-shade-7 padding-6 flex flex-row flex-justify-between flex-align-center">
          <div className="flex flex-row">
            <ProfilePicture imageClassName="paaf_grid--avatar_1_5" baseClassName="margin-r-4" profileUrl={user?.profilePicture} />
            <div className="flex flex-column">
              <div className="text-ld text-bold">{getUserName(user)}</div>
              <div className="text-md text-grey-shade-2">{user?.jobTitle}</div>
              <div className="text-sm text-grey-shade-2">{user?.homeOffice}, {user?.hostOfficeRegion}</div>
            </div>
          </div>

          {isAdminPapRole && (
            <MultiSelectDropdown
              onChange={handleArchetypeChanges}
              options={archetypeState?.data}
              placeholder={"MDP Archetype"}
              isClearable={false}
              isLoading={archetypeState.fetching}
              defaultValues={userAffiliationsState?.data?.[0]?.mdpArcheType}
              closeMenuOnSelect={true}
              hideSelectedOptions={true}
              isSearchable={false}
              outlineSquare
              baseClassName={`mdp-archetype-dropdown`}
              controlShouldRenderValue
            />
          )}

          {/* <CustomButton
            buttonText="View Archived Comments"
            primaryButton
            iconPosition={ICON_POSITION.RIGHT}
            iconProps={{
              name: CUSTOM_SVG_ICON.CaretDown,
              svgType: SVGType.CUSTOM,
              size: "small",
              baseclassname: "margin-l-2"
            }}
          /> */}
        </div>
        }

        <div className="custom-table margin-t-6">
          <table>
            <thead>
              <tr>
                <th className="flex flex-justify-around flex-align-baseline">
                  Parent Practice Area
                  <div className="expand-collapse-wrapper">
                    <CheckboxLabel
                      labelTxt={globalChecked ? "Collapse All" : "Expand All"}
                      handleChange={handleExpandCollapse}
                      checked={globalChecked}
                      baseClassName="left-align"
                    />
                  </div>
                </th>
                <th>Topic/Sector</th>
                <th>Affiliation</th>
                <th>S</th>
                <th>Comments</th>
                <th>Previous Comments</th>
                {(isAdminPapRole || isPapRole) && (
                  <th>% Lead Allocation</th>
                )}
              </tr>
            </thead>
            <tbody>
              {
                !userAffiliationsState.fetching && !affiliationLevelState.fetching && !practiceTopicPermissionState.fetching && practiceAreaState.data?.dropdown.map(renderCategoryPracticeArea)
              }
            </tbody>
          </table>
        </div>
        {(userAffiliationsState.fetching || practiceTopicPermissionState.fetching || userAffiliationsState.operationProgress || fetching || practiceAreaState.fetching || affiliationLevelState.fetching) && <Loading isGlobal />}
      </div>
      <div className="edit-affiliations-actions flex flex-justify-end background-secondary-color padding-l-10 padding-r-10 padding-t-6 padding-b-6">
        <CustomButton
          buttonText="Cancel"
          round
          transparent
          outline
          primaryButton
          baseclassname={"white-outline-button margin-r-4"}
          handleClick={handleBackNavigation}
        />

        <CustomButton
          disabled={(!Object.keys(topicChanges).length) && (mdpArcheType === '')}
          buttonText="Submit"
          primaryButton
          round
          handleClick={handleSubmitClick}
        />
      </div>
    </Fragment>
  );
}

export default EditAffiliations;