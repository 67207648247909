import URLRoutes from "urlRoutes";
import { getIntrestedAffiliations, presetFiltersList, previousCommentsList, userAllAffiliationsList } from "./list";

export function getIntrestedAffiliationsRequest() {
  return getIntrestedAffiliations.request({
    method: "get", url: URLRoutes.server.getAffiliations,
    urlParams: {
      AFFILIATIONS_TYPE: "INTERESTED"
    }
  });
}

export function addIntrestedAffiliationsRequest(topicIds: Array<string>) {
  return getIntrestedAffiliations.multiAddRequest({
    method: "post", url: URLRoutes.server.addAffiliations, body: {
      topicIds
    }
  });
}

export function deleteIntrestedAffiliationsRequest(topicId: string) {
  return getIntrestedAffiliations.deleteRequest({
    method: "delete", url: URLRoutes.server.deleteAffiliation, urlParams: {
      topicId
    }
  });
}

export function getAllPresetRequest() {
  return presetFiltersList.request({
    method: "get", url: URLRoutes.server.getPresetFilter
  });
}

export function addPresetRequest(body: any) {
  return presetFiltersList.addRequest({
    method: "post", url: URLRoutes.server.getPresetFilter, body
  });
}

export function editPresetRequest(body: any, presetId: string) {
  return presetFiltersList.editRequest({
    method: "put", url: URLRoutes.server.getAllPreset, body,
    urlParams: {
      id: presetId
    }
  });
}

export function deletePresetRequest(id: string) {
  return presetFiltersList.deleteRequest({
    method: "delete", url: URLRoutes.server.getAllPreset,
    urlParams: {
      id
    }
  });
}


export function requestUserAllAffiliationsList(hrId: string) {
  return userAllAffiliationsList.request({
    method: "get",
    url: URLRoutes.server.userAffiliations,
    urlParams: {
      hrId
    }
  })
}

export function requestPreviousComments(hrId: string, topicId: string) {
  return previousCommentsList.request({
    method: "get",
    url: URLRoutes.server.previousComments,
    urlParams: {
      hrId,
      topicId
    }
  })
}


export function updateUserAffiliations(updatedTopicAffiliation: any, hrId: string, mdpArchetype: string) {
  const body: any = {
    hrId,
    affiliationsUpdated: Object.keys(updatedTopicAffiliation).length ? Object.keys(updatedTopicAffiliation).map((topicId: string) => ({topicId, ...updatedTopicAffiliation[topicId]})) : [],
    mdpArcheType: mdpArchetype ? mdpArchetype : ''
  };
  return userAllAffiliationsList.multiEditRequest({
    url: URLRoutes.server.updateUserAffiliations,
    method: "put",
    body
  })
}