import OktaAuth from "@okta/okta-auth-js";
import { getListState, IListState } from "baseProvider/list";
import { getPageFetchedState, getPaginationState, IPaginationState } from "baseProvider/pagination";
import { getRextState, IRextState } from "baseProvider/rext";
import { ApplicationState, IPeople, ModalState } from "store/reducers";

export function getModalState(state: ApplicationState): ModalState {
  return state.modalState
}

export function getOktaAuthState(state: ApplicationState): OktaAuth {
  return state.applicationContext.okatAuth
}

export function getToken(state: ApplicationState): string {
  const { okatAuth } = state.applicationContext; 
  if(okatAuth) {
    return `Bearer ${okatAuth.getAccessToken()!}`;
  }
  return "";
}

export function getBaseUrl(isPeople: boolean): (state: ApplicationState) => string {
  return (state: ApplicationState): string => {
    if (isPeople) {
      return process.env.REACT_APP_PEOPLE_API_URL!
    }
    return process.env.REACT_APP_API_URL!;
  }
}

export function getBaseUrlAPIKEY(isPeople: boolean): (state: ApplicationState) => string {
  return (state: ApplicationState): string => {
    if (isPeople) {
      return process.env.REACT_APP_PEOPLE_API_KEY!
    }
    return process.env.REACT_APP_API_KEY!;
  }
}

export function getActiveAffiliations(state: ApplicationState): IRextState {
  return getRextState(state.allAffiliationsState.activeAffiliations, []);
}

export function getIntrestedAffiliations(state: ApplicationState): IListState {
  return getListState(state.allAffiliationsState.intrestedAffiliations, []);
}

export function getLeadAffiliations(state: ApplicationState): IRextState {
  return getRextState(state.allAffiliationsState.leadAffiliations, []);
}

export function getLocations(state: ApplicationState): IRextState {
  return getRextState(state.common.locationsState, {dropdown: [], mappingIds: undefined});
}

export function getGlobalTitleState(state: ApplicationState): IRextState {
  return getRextState(state.common.globalTitleState, []);
}

export function getCarrerStepState(state: ApplicationState): IRextState {
  return getRextState(state.common.carrerStepState, []);
}

export function getCarrerTrackState(state: ApplicationState): IRextState {
  return getRextState(state.common.carrerTrackState, []);
}

export function getPracticeAreaState(state: ApplicationState): IRextState {
  return getRextState(state.common.practiceAreaState, {dropdown: [], mappingIds: undefined});
}

export function getPracticeAreaForUserState(state: ApplicationState): IRextState {
  return getRextState(state.allAffiliationsState.practiceAreaState, {dropdown: [], mappingIds: undefined});
}

export function getAffiliationState(state: ApplicationState): IRextState {
  return getRextState(state.common.affiliationState, []);
}

export function getPresetFilterState(state: ApplicationState): IListState {
  return getListState(state.presetFilterState, []);
}

export function getPeopleState(peopleId: string) {
  return (state: ApplicationState): IPeople => state.peopleState[peopleId];
}

export function getAllPeopleState(state: ApplicationState) {
  return  state.peopleState;
}

export function getUserAllAffiliationsState(state: ApplicationState): IListState {
  return getListState(state.userAllAffiliationsState, []);
}

export function getFeaturesState(state: ApplicationState): IRextState {
  return getRextState(state.featuresState, undefined!);
}


export function getPaafUserState(state: ApplicationState): IPaginationState {
  return getPaginationState(state.userPaginationState, []);
}

export function paafUserFetchPageState(state: ApplicationState) {
  return getPageFetchedState(state.userPaginationState);
}

export function getAllRolesState(state:ApplicationState): IRextState {
  return getRextState(state.allRolesState, [])
}

export function getSearchBCGUsers(state:ApplicationState): IRextState {
  return getRextState(state.serachBcgUserState, [])
}

export function getArchiveReports(state:ApplicationState): IRextState {
  return getRextState(state.archiveReportState, [])
}

export function changeReportFetchPageState(state: ApplicationState): IPaginationState {
  return getPaginationState(state.changeReportState, []);
}
export function allChangeReportPageState(state: ApplicationState) {
  return getPageFetchedState(state.changeReportState);
}

export function gettoggleColumnState(state:ApplicationState):IRextState{
  const rextState = getRextState(state.toggleColumnState, {});
  if(rextState.error) {
    return {
      ...rextState,
      data: {}
    }
  } else {
    return rextState;
  }
}

export function allAffiliationFetchPageState(state: ApplicationState): IPaginationState {
  return getPaginationState(state.allAffiliationState, []);
}


export function allAffiliationPageState(state: ApplicationState) {
  return getPageFetchedState(state.allAffiliationState);
}

export function getUserRoleState(state:ApplicationState):IRextState{
  return getRextState(state.userRoleState, undefined!)
  }

export function getPreviousCommentsState(state: ApplicationState): IListState {
  return getListState(state.previousCommentsState, []);
}

export function getPracticeTopicPermissionState(state: ApplicationState): IRextState {
  return getRextState(state.practiceTopicsPermissionsState, {});
}

export function getOrganizationsState(state: ApplicationState): IRextState {
  return getRextState(state.common.snowflakeOrganizationState, []);
}

export function getSnoeflakeRegionsState(state: ApplicationState): IRextState {
  return getRextState(state.common.snowflakeRegionState, {dropdown: [], mappingIds: undefined});
}

export function getSfAllAffiliationFetchPageState(state: ApplicationState): IPaginationState {
  return getPaginationState(state.snowflakeAllAffiliationState, []);
}

export function getSfAllAffiliationFetchTableVieweState(state: ApplicationState): IPaginationState {
  return getPaginationState(state.snowflakeAllAffiliationTableViewState, []);
}

export function getSfAllAffiliationFetchMatrixVieweState(state: ApplicationState): IPaginationState {
  return getPaginationState(state.snowflakeAllAffiliationMatrixViewState, []);
}

export function getCohortState(state: ApplicationState): IRextState {
  return getRextState(state.common.cohortDropdownState, []);
}

export function getJobTitleState(state: ApplicationState): IRextState {
  return getRextState(state.common.jobTitleState, []);
}

export function getArchetypeState(state: ApplicationState): IRextState {
  return getRextState(state.common.archetypeState, []);
}
