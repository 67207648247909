import { SagaIterator } from "redux-saga";
import { all, fork } from "redux-saga/effects";
import { archiveReports, getActiveAffiliations, getAffiliation, getAllRoles, getArchetypeRext, getCarrerStep, getCarrerTrack, getChort, getFeatures,
  getGlobalTitle, getJobTitles, getLeadAffiliations, getLocations,
  getOrganization,
  getPracticeArea, getPracticeAreaForUser, getSnoFlakeRegions, practiceTopicsPermissionsRext, searchBCGUsers, toggleColumnRext, userRoleRext } from "store/rext";
import { getIntrestedAffiliations, presetFiltersList, previousCommentsList, userAllAffiliationsList } from 'store/list';
import { watchPeopleEvent } from "./people";
import { allAffiliationPagination, changeReportPagination, paafUserPagination, snowflakeAllAffiliationPagination, snowflakeAllAffiliationPaginationMatrixView, snowflakeAllAffiliationPaginationTableView } from "store/pagination";

export default function* root(): SagaIterator {
  yield all([
    fork(getActiveAffiliations.saga),
    fork(getIntrestedAffiliations.saga),
    fork(getLeadAffiliations.saga),
    fork(getLocations.saga),
    fork(getGlobalTitle.saga),
    fork(getCarrerStep.saga),
    fork(getCarrerTrack.saga),
    fork(getPracticeArea.saga),
    fork(getPracticeAreaForUser.saga),
    fork(getAffiliation.saga),
    fork(presetFiltersList.saga),
    fork(watchPeopleEvent),
    fork(userAllAffiliationsList.saga),
    fork(getFeatures.saga),
    fork(paafUserPagination.saga),
    fork(getAllRoles.saga),
    fork(searchBCGUsers.saga),
    fork(archiveReports.saga),
    fork(changeReportPagination.saga),
    fork(allAffiliationPagination.saga),
    fork(toggleColumnRext.saga),
    fork(userRoleRext.saga),
    fork(previousCommentsList.saga),
    fork(practiceTopicsPermissionsRext.saga),
    fork(getSnoFlakeRegions.saga),
    fork(getOrganization.saga),
    fork(snowflakeAllAffiliationPagination.saga),
    fork(snowflakeAllAffiliationPaginationTableView.saga),
    fork(snowflakeAllAffiliationPaginationMatrixView.saga),
    fork(getChort.saga),
    fork(getJobTitles.saga),
    fork(getArchetypeRext.saga),
  ])
}