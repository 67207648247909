export const server = {
    getAffiliations: "/api/affiliation/:AFFILIATIONS_TYPE",
    addAffiliations: "/api/affiliation",
    deleteAffiliation: "/api/affiliation/:topicId",
    location: "/location",
    getAffiliationLevel: "/api/affiliation/level",
    getPracticeArea: "/api/affiliation/pa-topic-mapping",
    getPracticeAreaForUser: "/api/affiliation/user-pa-topic-mapping",
    getOptions: "/api/search/option/:optionId",
    getGlobalTitles: "/api/search/option/JOBTITLE",
    getAllPreset: "/api/search/preset-filter/:id",
    getPresetFilter: "/api/search/preset-filter",
    people: "/domain/v2/people",
    employeeData: "/api/snowflake/employee-data",
    features: "/api/roles/entitlements",
    listAllUsers: "/api/roles/search",
    allUsersOperation: "/api/roles/:hrId",
    userRolesOperation: "/api/roles",
    allRoles: "/api/roles/enum",
    archiveReports: "/api/report/archive",
    archiveReportsDownload: "/api/report/archive/download/:filename",
    allChangeReports: "/api/report/change-report",
    allAffiliations: "/api/search/:gridType",
    toggleChangeReports: "/api/preference/grid-columns/:gridType",
    userAffiliations: "/api/affiliation/:hrId/affiliation",
    previousComments: "/api/affiliation/:hrId/affiliation/:topicId/comment",
    userRoles: "/api/roles/:userId",
    updateUserAffiliations: "/api/affiliation",
    topicPracticePermission: "/api/roles/editable-topics",
    snowflakeRegion: "/api/snowflake/office-dropdown",
    snowflakeOrganization: "/api/search/organizations",
    snowflakeEmployeeSearch: "/api/snowflake/employee-search",
    snowflakeEmployeeSearchTableView: "/api/snowflake/all_affiliation_table_view",
    snowflakeEmployeeSearchMatrixView: "/api/snowflake/all_affiliation_matrix_view",
    cohortDropdownUrl: "/api/search/option/COHORT",
    getArchetypeUrl: "/api/affiliation/archetype",
}